import PropTypes from 'prop-types';
import classNames from 'classnames';
import Link from 'next/link';
import { platformTypes } from '@/utility/constants';

const buttonType = {
  primary: 'primary',
  secondary: 'secondary',
  disabled: 'disabled',
  outline: 'outline',
  neutral: 'neutral',
  'static-primary': 'static-primary',
  disabledStaticPrimary: 'disabledStaticPrimary',
  danger: 'danger',
  'danger-outline': 'danger-outline',
  custom: 'custom',
  plain: 'plain'
};

const getPlatformTypeClassName = (platformType) => {
  if (!platformType) {
    return '';
  }

  switch (platformType.toLowerCase()) {
    case platformTypes.whatsapp:
      return 'bg-npl-social-brand-colours-whats-app text-npl-text-icon-on-light-surface-primary';
    case platformTypes.discord:
      return 'bg-npl-social-brand-colours-discord text-npl-base-white';
    case platformTypes.telegram:
      return 'bg-npl-social-brand-colours-telegram text-npl-base-white';
    case platformTypes.facebook:
      return 'bg-npl-social-brand-colours-facebook text-npl-base-white';
    case platformTypes.linkedin:
      return 'bg-npl-social-brand-colours-linked-in text-npl-base-white';
    case platformTypes.slack:
      return 'bg-npl-social-brand-colours-slack text-npl-base-white';
    default:
      return '';
  }
};

const buttonSize = {
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl'
};

const RoundedButton = ({
  displayType = buttonType.secondary,
  size = buttonSize.md,
  children,
  disabled,
  customClassNames,
  customInnerClassNames = '',
  isLoading = false,
  link,
  isAbsoluteUrl,
  isOpenedNewTab,
  type = 'button',
  platformType,
  ...btnProps
}) => {
  const getDisplayTypeClassnames = ({ type }) => {
    switch (type) {
      case buttonType.primary:
        return 'bg-primary hover:bg-yellow-60 active:bg-yellow-50 text-npl-text-icon-on-light-surface-primary';
      case buttonType.secondary:
        return 'bg-none';
      case buttonType.disabled:
        return 'bg-grey-neutral90 text-neutral-70 cursor !cursor-not-allowed';
      case buttonType.disabledStaticPrimary:
        return 'bg-npl-neutral-dark-solid-10 text-neutral-70 cursor cursor-not-allowed';
      case buttonType.disabledPrimary:
        return 'bg-npl-yellow-light-solid-9 text-npl-text-icon-on-light-surface-primary cursor cursor-not-allowed';
      case buttonType.outline:
        return 'bg-none border-1 border-npl-neutral-light-solid-7 hover:bg-button-outline-hover active:bg-npl-neutral-light-solid-4 active:border-npl-neutral-light-solid-8';
      case buttonType.neutral:
        return 'bg-neutral-10 text-npl-text-icon-on-dark-primary hover:bg-neutral-40 active:bg-neutral-50';
      case buttonType.danger:
        return 'bg-npl-error-light-9 text-npl-text-icon-on-dark-primary';
      case buttonType['danger-outline']:
        return 'border-1 border-npl-error-light-7 hover:border-npl-error-light-8 hover:bg-npl-error-light-3 active:border-npl-error-light-8 active:bg-npl-error-light-4';
      case buttonType['static-primary']:
        return 'bg-npl-neutral-dark-solid-3 text-npl-text-icon-on-dark-primary';
      case buttonType.plain:
        return 'text-npl-text-icon-on-light-surface-primary hover:bg-npl-neutral-light-solid-3 active:bg-npl-neutral-light-solid-5';
      case buttonType.custom:
        return '';
      default:
        return 'bg-primary';
    }
  };

  const getSizeClassnames = ({ size }) => {
    switch (size) {
      case buttonSize.sm:
        return 'h-32 text-14 font-medium px-16';
      case buttonSize.md:
        return 'h-[40px] text-14 font-medium px-24';
      case buttonSize.lg:
        return 'h-[48px] text-button-md font-medium px-24';
      case buttonSize.xl:
        return 'h-[56px] text-button-lg font-medium px-24';
      default:
        return 'h-[40px] text-16 font-medium px-24';
    }
  };

  let disabledClassName =
    displayType === 'primary'
      ? buttonType.disabledPrimary
      : buttonType.disabled;

  if (displayType === buttonType['static-primary']) {
    disabledClassName = buttonType.disabledStaticPrimary;
  }

  const allClassNames = classNames(
    'rounded-full flex space-x-8 items-center justify-center cursor-pointer text-neutral-10 duration-150',
    getDisplayTypeClassnames({
      type: disabled ? disabledClassName : displayType
    }),
    getSizeClassnames({ size }),
    getPlatformTypeClassName(platformType),
    customClassNames
  );

  if (link) {
    const renderLinkElement = ({ href } = {}) => (
      <a
        href={href}
        {...(isOpenedNewTab ? { target: '_blank' } : {})}
        className={`${allClassNames} w-fit disabled:opacity-50`}
        disabled={disabled || isLoading}
        {...btnProps}>
        {isLoading && (
          <div
            className={`h-16 w-16 animate-spin rounded-full border-2 border-l-transparent ${
              disabled ? 'text-neutral-70' : 'border-neutral-10'
            }`}></div>
        )}
        <span className={customInnerClassNames}>{children}</span>
      </a>
    );

    return isAbsoluteUrl ? (
      renderLinkElement({
        href: link.includes('http') ? link : `//${link}`
      })
    ) : (
      <Link href={link} passHref legacyBehavior>
        {renderLinkElement()}
      </Link>
    );
  }

  return (
    <button
      className={`${allClassNames} disabled:opacity-50`}
      disabled={disabled || isLoading}
      type={type}
      {...btnProps}>
      {isLoading && (
        <div
          className={`h-16 w-16 animate-spin rounded-full border-2 border-l-transparent ${
            disabled ? 'text-neutral-70' : 'border-neutral-10'
          }`}></div>
      )}
      <span className={customInnerClassNames}>{children}</span>
    </button>
  );
};

RoundedButton.propTypes = {
  displayType: PropTypes.oneOf(Object.values(buttonType)),
  size: PropTypes.oneOf(Object.values(buttonSize)),
  disabled: PropTypes.bool,
  customClassNames: PropTypes.string,
  isLoading: PropTypes.bool,
  link: PropTypes.string,
  isOpenedNewTab: PropTypes.bool
};

export default RoundedButton;
