import classNames from 'classnames';
import React from 'react';

import Icon from './Icon';
import type { IconName } from './Icon';

export const NPL_BADGE_HIERARCHY = {
  SUCCESS: 'success',
  DANGER: 'danger',
  ACCENT: 'accent',
  INFO: 'info',
  OUTLINE: 'outline',
  ORANGE: 'orange'
} as const;

type BadgeHierarchy =
  (typeof NPL_BADGE_HIERARCHY)[keyof typeof NPL_BADGE_HIERARCHY];

type BadgeSize = 'xs' | 'sm' | 'md';

interface NPLBadgeProps {
  type: BadgeHierarchy;
  text: string | React.JSX.Element;
  size?: BadgeSize;
  leadIcon?: IconName<'common'>;
  tailIcon?: IconName<'common'>;
  tailIconOnClick?: () => void;
  darkMode?: boolean;
}

function NPLBadge({
  type,
  text,
  size = 'sm',
  leadIcon,
  tailIcon,
  tailIconOnClick,
  darkMode = false
}: NPLBadgeProps) {
  const getTypeClassName = (): string => {
    switch (type) {
      case NPL_BADGE_HIERARCHY.ACCENT:
        return 'text-npl-yellow-light-solid-11 bg-npl-yellow-light-solid-3';
      case NPL_BADGE_HIERARCHY.DANGER:
        return 'text-npl-error-light-11 bg-npl-error-light-3';
      case NPL_BADGE_HIERARCHY.SUCCESS:
        return 'text-npl-success-light-11 bg-npl-success-light-3';
      case NPL_BADGE_HIERARCHY.INFO:
        if (darkMode) {
          return 'text-npl-neutral-dark-alpha-11 bg-npl-neutral-dark-alpha-3';
        } else {
          return 'text-npl-neutral-light-alpha-11 bg-npl-neutral-light-alpha-3';
        }
      case NPL_BADGE_HIERARCHY.OUTLINE:
        if (darkMode) {
          return 'border-1 border-npl-neutral-dark-alpha-7 text-npl-text-secondary-on-dark';
        } else {
          return 'border-1 border-npl-neutral-light-alpha-7 text-npl-text-secondary-on-light';
        }
        return 'text-npl-neutral-light-solid-11 bg-npl-neutral-light-solid-3';
      case NPL_BADGE_HIERARCHY.ORANGE:
        return 'text-npl-orange-light-11 bg-npl-orange-light-3';
      default:
        return '';
    }
  };

  const getIconColorClassName = (): string => {
    switch (type) {
      case NPL_BADGE_HIERARCHY.ACCENT:
        return '#946800';
      default:
        return '#1B1B18';
    }
  };

  const getTextSizeClassName = (): string => {
    switch (size) {
      case 'xs':
        return 'text-overline-xs font-semibold';
      case 'sm':
        return 'text-label-sm';
      case 'md':
        return 'text-label-md';
      default:
        return 'text-label-sm';
    }
  };

  const getPaddingClassName = (): string => {
    switch (size) {
      case 'sm':
        return 'py-2 px-4';
      case 'md':
        return 'p-8';
      default:
        return 'py-2 px-4';
    }
  };

  return (
    <div
      className={`flex items-center gap-6 rounded-4 ${getTypeClassName()} ${getPaddingClassName()}`}>
      {leadIcon && (
        <Icon
          name={leadIcon}
          width={16}
          height={16}
          fill={getIconColorClassName()}
        />
      )}
      <div className={`${getTextSizeClassName()} text-center font-medium`}>
        {text}
      </div>
      {tailIcon && (
        <div
          className={classNames({ 'cursor-pointer': tailIconOnClick })}
          onClick={tailIconOnClick}>
          <Icon
            name={tailIcon}
            width={16}
            height={16}
            fill={getIconColorClassName()}
          />
        </div>
      )}
    </div>
  );
}

export default NPLBadge;
