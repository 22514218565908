import { PRICE_TYPES } from '@/components/common/PricingSectionAndDiscounts/constants';
import { checkMember } from '@/services/communitiesService';
import {
  getCommunityDataForAnalytics,
  trackGAEvent
} from '@/utility/analytics';
import {
  CLICKED_MANAGER_VIEW_EVENT,
  CLICKED_MEMBER_VIEW_EVENT,
  CLICKED_VIEW_PROFILE_EVENT
} from '@/utility/analyticsConsts';
import { t } from '@/utility/localization';
import { formatNumber } from '@/utility/helpers';

export const trackOpenMemberViewBtnClick = (user, activeCommunity) => {
  const communityDataForAnalytics =
    getCommunityDataForAnalytics(activeCommunity);

  const params = {
    email: user?.email,
    ...communityDataForAnalytics
  };
  trackGAEvent(CLICKED_MEMBER_VIEW_EVENT, params);
};

export const trackOpenManagerViewBtnClick = (user, activeCommunity) => {
  const communityDataForAnalytics =
    getCommunityDataForAnalytics(activeCommunity);

  const params = {
    email: user?.email,
    ...communityDataForAnalytics
  };
  trackGAEvent(CLICKED_MANAGER_VIEW_EVENT, params);
};

export const trackOpenProfileSettingBtnClick = (user) => {
  const params = {
    email: user?.email
  };
  trackGAEvent(CLICKED_VIEW_PROFILE_EVENT, params);
};

export const trackLeftNavItemClick = (navItem, user, activeCommunity) => {
  const { gtmAnalyticsActionId } = navItem;
  if (!gtmAnalyticsActionId) return;

  const communityDataForAnalytics =
    getCommunityDataForAnalytics(activeCommunity);

  const params = {
    email: user?.email,
    ...communityDataForAnalytics
  };
  trackGAEvent(gtmAnalyticsActionId, params);
};

export const minAmountForCurrencyInCents = (currency) => {
  switch (currency) {
    case 'USD':
      return 100;
    case 'INR':
      return 5000;
    case 'BRL':
      return 300;
    case 'JPY':
      return 5000;
    default:
      return 100;
  }
};

export const maxAmountForCurrencyInCents = (currency) => {
  switch (currency) {
    case 'USD':
      return 99999900;
    case 'INR':
      return 99999900;
    case 'VND':
      return 99999999900;
    case 'JPY':
      return 999999900;
    default:
      return 99999900;
  }
};

export const checkIfUserIsMemberOfActiveCommunity = async (
  activeCommunityId,
  token
) => {
  //assume that the current user is a manager of the active community
  let isMemberOnlyInActiveCommunity = false;
  if (activeCommunityId) {
    const { data, error } = await checkMember(activeCommunityId, token);

    if (error) {
      return isMemberOnlyInActiveCommunity;
    }

    //if this user is not an admin and either a manager of the active community, we know this user is just a member
    isMemberOnlyInActiveCommunity =
      data?.isSubscriber &&
      !data?.roles?.admin &&
      !data?.roles?.manager &&
      !data?.roles?.owner;
  }
  return isMemberOnlyInActiveCommunity;
};

export const getPriceText = (
  price,
  discountedPrice,
  currency,
  priceType,
  darkMode
) => {
  if (priceType === PRICE_TYPES.FLEXIBLE) {
    return t('pay-what-you-want');
  }

  if (price == 0 || (!price && !priceType)) {
    return t('free');
  }

  const formattedPrice = formatNumber(price, true, true);
  const formattedDiscountPrice = formatNumber(discountedPrice, true, true);

  if (discountedPrice !== null && discountedPrice !== undefined)
    return (
      <>
        {currency} {formattedDiscountPrice}
        <span
          className={`ml-4 line-through ${
            darkMode
              ? 'text-npl-text-icon-on-dark-tertiary'
              : 'text-npl-text-icon-on-light-surface-tertiary'
          }`}>
          {formattedPrice}
        </span>
      </>
    );

  return (
    <>
      {currency} {formattedPrice}
    </>
  );
};

export const getTimeValue = (timeInSeconds) => {
  const newHour = Math.floor(timeInSeconds / 60);
  const newMinute = timeInSeconds % 60;

  // Format the new time as a string in "HH:mm" format
  const formattedTime = `${newHour.toString().padStart(2, '0')}:${newMinute
    .toString()
    .padStart(2, '0')}`;

  return formattedTime;
};

export const calculateTimeInMinutes = (timeString) => {
  // Split the time string into hours and minutes
  const [hours, minutes] = timeString.split(':').map(Number);

  // Calculate the total minutes
  const totalMinutes = hours * 60 + minutes;

  return totalMinutes;
};

export const getCleanTimeFromTimezoneId = (timezoneId) => {
  const timeZoneWithoutContinent = timezoneId.split('/')?.[1];
  const cleanedTimeZoneWithoutContinent =
    timeZoneWithoutContinent?.replaceAll('_', ' ');
  return cleanedTimeZoneWithoutContinent;
};
