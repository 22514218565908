import React from 'react';

const SearchIcon = ({
  fillColor = '#fff',
  width = '24',
  height = '24',
  customClassName
}) => {
  return (
    <svg
      className={'c-SearchIcon ' + customClassName}
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 24">
      <path
        fill={fillColor}
        fillRule="evenodd"
        d="M10.222 5a5.222 5.222 0 100 10.444 5.222 5.222 0 000-10.444zM3 10.222a7.222 7.222 0 1112.987 4.351l4.72 4.72a1 1 0 01-1.414 1.414l-4.72-4.72A7.222 7.222 0 013 10.222z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export default SearchIcon;
