import { t } from '@/utility/localization';
import {
  MEMBER_AFFILIATE_PAYOUT_ROUTE,
  MEMBER_AFFILIATE_PRODUCTS_ROUTE
} from '@/utility/routesHelper';

export const PROFILE_TAB_KEY = 'profileTab';
export const SOCIAL_MEDIA_TAB_KEY = 'socialMediaTab';
export const VIDEO_TAB_KEY = 'videoTab';
export const COMMENT_TAB_KEY = 'commentTab';
export const PASSWORD_TAB_KEY = 'passwordTab';

export const SETTINGS_PAGE_PROFILE_ENDPOINT = 'profile';
export const SETTINGS_PAGE_MEMBERSHIP_ENDPOINT = 'membership';
export const SETTINGS_PAGE_ACCOUNT_ENDPOINT = 'account';

export const SETTINGS_PAGE_PROFILE_PATH = '/user/profile';
export const SETTINGS_PAGE_ACCOUNT_PATH = '/user/account';

export const SETTINGS_PAGE_MEMBERSHIP_PATH = '/user/membership';

export const SETTINGS_PAGE_EMAIL_NOTIFICATIONS_PATH =
  '/user/email-notifications';

export const SETTINGS_PAGE_CALENDAR_PATH = '/user/calendar';
export const SETTINGS_PAGE_CALENDAR_ENDPOINT = 'calendar';

export const SETTINGS_PAGE_EMAIL_NOTIFICATION_ENDPOINT =
  'email-notifications';

export const settingsPagePaths = [
  SETTINGS_PAGE_PROFILE_PATH,
  SETTINGS_PAGE_MEMBERSHIP_PATH
];

export const NAV_GROUP = {
  SETTINGS: 'SETTINGS',
  AFFILIATE: 'AFFILIATE'
};

export const getSettingPageProfile = () => ({
  name: 'SETTINGS_PAGE_PROFILE',
  label: t('profile'),
  labelKey: 'profile',
  pathname: SETTINGS_PAGE_PROFILE_PATH,
  endpoint: SETTINGS_PAGE_PROFILE_ENDPOINT,
  groupId: NAV_GROUP.SETTINGS
});

export const getSettingPageAccount = () => ({
  name: 'SETTINGS_PAGE_ACCOUNT',
  label: t('account'),
  labelKey: 'account',
  pathname: SETTINGS_PAGE_ACCOUNT_PATH,
  endpoint: SETTINGS_PAGE_ACCOUNT_ENDPOINT,
  groupId: NAV_GROUP.SETTINGS
});

export const getSettingPageEmailNotifications = () => ({
  name: 'SETTINGS_PAGE_EMAIL_NOTIFICATIONS',
  label: t('email-notifications'),
  labelKey: 'email-notifications',
  pathname: SETTINGS_PAGE_EMAIL_NOTIFICATIONS_PATH,
  endpoint: SETTINGS_PAGE_EMAIL_NOTIFICATION_ENDPOINT,
  groupId: NAV_GROUP.SETTINGS
});

export const getSettingPageCalendar = () => ({
  name: 'SETTINGS_PAGE_CALENDAR',
  label: t('calendar'),
  labelKey: 'calendar',
  pathname: SETTINGS_PAGE_CALENDAR_PATH,
  endpoint: SETTINGS_PAGE_CALENDAR_ENDPOINT,
  groupId: NAV_GROUP.SETTINGS
});

export const getSettingPageMembership = () => ({
  name: 'SETTINGS_PAGE_MEMBERSHIP',
  label: t('memberships'),
  labelKey: 'memberships',
  pathname: SETTINGS_PAGE_MEMBERSHIP_PATH,
  endpoint: SETTINGS_PAGE_MEMBERSHIP_ENDPOINT,
  groupId: NAV_GROUP.SETTINGS
});

export const getAffiliateProductsPage = () => ({
  name: 'MEMBER_AFFILIATE_PRODUCTS',
  label: 'Products',
  labelKey: 'products',
  pathname: MEMBER_AFFILIATE_PRODUCTS_ROUTE,
  groupId: NAV_GROUP.AFFILIATE
});

export const getAffiliatePayoutPage = () => ({
  name: 'MEMBER_AFFILIATE_PAYOUT',
  label: 'Payout',
  labelKey: 'payout',
  pathname: MEMBER_AFFILIATE_PAYOUT_ROUTE,
  groupId: NAV_GROUP.AFFILIATE
});

export const pagesList = () => [
  getSettingPageProfile(),
  getSettingPageAccount(),
  getSettingPageMembership(),
  getSettingPageEmailNotifications(),
  getSettingPageCalendar(),
  getAffiliateProductsPage(),
  getAffiliatePayoutPage()
];

export const getSettingsNavItems = () => [
  getSettingPageProfile(),
  getSettingPageAccount(),
  getSettingPageMembership(),
  getSettingPageEmailNotifications(),
  getSettingPageCalendar()
];

export const getAffiliateNavList = () => [
  getAffiliateProductsPage(),
  getAffiliatePayoutPage()
];

export const getAllNavPages = () => [
  getSettingPageProfile(),
  getSettingPageAccount(),
  getSettingPageMembership(),
  getSettingPageEmailNotifications(),
  getSettingPageCalendar(),
  getAffiliateProductsPage(),
  getAffiliatePayoutPage()
];

export const getFormattedNavGroups = ({
  currentPathname,
  showAffiliateGroup
}) => {
  // Initialize
  let settingsGroup = {
    id: NAV_GROUP.SETTINGS,
    label: t('settings'),
    icon: 'settings-01',
    isActive: false,
    navItems: []
  };
  let affiliateGroup = {
    id: NAV_GROUP.AFFILIATE,
    label: t('affiliates'),
    icon: 'user-money',
    isActive: false,
    navItems: []
  };

  // Populate navItems and isActive
  getAllNavPages().forEach((item) => {
    const isActive = item.pathname.includes(currentPathname);

    const navItem = {
      id: item.name,
      label: t(item.labelKey) || item.label,
      link: item.pathname,
      isActive,
      hidden: false
    };

    switch (item.groupId) {
      case NAV_GROUP.SETTINGS:
        settingsGroup.navItems.push(navItem);

        if (isActive) {
          settingsGroup.isActive = true;
        }
        break;
      case NAV_GROUP.AFFILIATE:
        affiliateGroup.navItems.push(navItem);

        if (isActive) {
          affiliateGroup.isActive = true;
        }
        break;
      default:
        break;
    }
  });

  return [
    settingsGroup,
    showAffiliateGroup ? affiliateGroup : null
  ].filter(Boolean);
};

export const STATUS_PENDING = 'PENDING';
