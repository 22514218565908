import { t } from '@/utility/localization';
import { useEffect } from 'react';
import PropTypes from 'prop-types';
import SearchIcon from '@/components/common/Icons/SearchIcon';
import debounce from 'lodash.debounce';
import { useState } from 'react';
import { useCallback } from 'react';
import NPLIconButton from '@/components/npl/NPLIconButton';

const SearchBox = ({
  initialValue = '',
  placeholder = t('enter-name-or-email'),
  onChange,
  customClassNames,
  customInputClassNames,
  onInputBlur = () => {}
}) => {
  const [search, setSearch] = useState(initialValue);

  const debounceChange = useCallback(
    debounce((value) => onChange(value), 500),
    [onChange]
  );

  useEffect(() => {
    setSearch(initialValue);
  }, [initialValue]);

  const handleChange = (e) => {
    const value = e.target.value;
    setSearch(value);
    debounceChange(value);
  };

  const clearSearch = () => {
    onChange('');
    setSearch('');
  };

  return (
    <div
      className={`relative flex items-center rounded-32 border-1 border-light-aa px-12 py-8 focus-within:border-1 focus-within:border-black ${customClassNames}`}>
      <SearchIcon fillColor="#666666" width={18} height={18} />
      <input
        className={`ml-6 w-full bg-transparent text-label-md text-npl-text-icon-on-light-surface-primary focus:outline-none ${customInputClassNames}`}
        placeholder={placeholder}
        onChange={handleChange}
        value={search}
        onBlur={() => {
          onInputBlur && onInputBlur();
        }}
      />

      {search !== '' && (
        <div
          className="absolute right-8 top-0 flex h-full items-center justify-center"
          onClick={clearSearch}
          tabIndex={0}
          role="button">
          <NPLIconButton
            size="sm"
            icon="x-circle-filled"
            hierarchy="plain"
            tooltipText={t('clear')}
          />
        </div>
      )}
    </div>
  );
};

SearchBox.propTypes = {
  initialValue: PropTypes.string,
  placeholder: PropTypes.string,
  customClassNames: PropTypes.string,
  customInputClassNames: PropTypes.string,
  onChange: PropTypes.func,
  onInputBlur: PropTypes.func
};

export default SearchBox;
