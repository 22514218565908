import { t } from '@/utility/localization';
import React, { useEffect, useRef, useState } from 'react';

import LinkAwareText from '../LinkAwareText';
import PropTypes from 'prop-types';

const ClampText = ({
  text = '',
  line = 1,
  customClassNames = '',
  customCTA,
  ctaCustomClassNames = '',
  isDefaultExpanded = false,
  collapsedCtaText = t('see-more2'),
  expandedCtaText = t('hide'),
  onlyExpandOnce = false,
  hideCTA = false,
  disableLinkAware = false,
  linkIsSecondaryColor = true
}) => {
  const textRef = useRef(null);

  const [isCtaVisible, setIsCtaVisible] = useState(true);
  const [isExpandedUIState, setIsExpandedUIState] =
    useState(isDefaultExpanded);

  const hasExpandedOnce = onlyExpandOnce && isExpandedUIState;

  const toggleCollapsedState = () => setIsExpandedUIState((prev) => !prev);

  // Mainly for tracking GA events
  const handleCustomCTA = () => {
    if (customCTA) {
      customCTA();
    }
  };

  // check if clamping is needed or not on the first load
  useEffect(() => {
    if (textRef.current)
      if (
        textRef.current.offsetHeight === textRef.current.scrollHeight &&
        !isDefaultExpanded
      )
        setIsCtaVisible(false);
  }, [text, line, isDefaultExpanded]);

  return (
    <div className={`relative ${customClassNames}`}>
      <div
        ref={textRef}
        style={{
          overflow: 'hidden',
          display: '-webkit-box',
          WebkitBoxOrient: 'vertical',
          WebkitLineClamp: isExpandedUIState ? 'unset' : `${line}`,
          wordBreak: 'break-word',
          whiteSpace: 'pre-line'
        }}>
        {disableLinkAware ? (
          text
        ) : (
          <span onClick={handleCustomCTA}>
            <LinkAwareText
              linkIsSecondaryColor={linkIsSecondaryColor}
              text={text}
              customCTA={customCTA}
            />
          </span>
        )}
      </div>

      {!hideCTA && !hasExpandedOnce && isCtaVisible && (
        <div
          role="button"
          tabIndex="0"
          className={`w-fit cursor-pointer bg-white-default font-semibold text-npl-text-icon-on-light-surface-tertiary ${
            isExpandedUIState
              ? 'ml-auto mt-16 pl-0 text-right'
              : 'absolute bottom-0 right-0 pl-8'
          } ${ctaCustomClassNames}`}
          onClick={toggleCollapsedState}>
          {isExpandedUIState ? expandedCtaText : collapsedCtaText}
        </div>
      )}
    </div>
  );
};

ClampText.propsTypes = {
  isDefaultExpanded: PropTypes.bool,
  text: PropTypes.string,
  line: PropTypes.number,
  customClassNames: PropTypes.string,
  ctaCustomClassNames: PropTypes.string,
  collapsedCtaText: PropTypes.string,
  expandedCtaText: PropTypes.string,
  onlyExpandOnce: PropTypes.bool,
  hideCTA: PropTypes.bool,
  disableLinkAware: PropTypes.bool
};

export default React.memo(ClampText);
