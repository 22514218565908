import config from '@/utility/config';

import adminProtectedAxiosRequest from './adminProtectedAxiosRequest';
import { getAxiosErrorMsg } from './index';

export const getCsvApiToken = async (communityId) => {
  const apiPath = `${config.communityApiBasePath}/api/v1/${communityId}/token`;
  const { data, error } = await adminProtectedAxiosRequest.get(apiPath);
  if (error) {
    return { error: getAxiosErrorMsg(error), data: null };
  }
  return { data: data?.token };
};
